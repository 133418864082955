import { NextRouter } from 'next/router';
import React from 'react';
import captureException from '../../utils/capture-exception';
import RKLButton from '../ui-kit/components/rkl-button';
import css from './error-boundary.module.css';

type ErrorBoundaryProps = {
    apiVersion?: string;
    router: NextRouter;
    children: React.ReactNode;
};
type ErrorBoundaryState = Readonly<{
    error?: Error;
    canShowTimeout: boolean;
}>;

export default class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    state: ErrorBoundaryState = {
        error: undefined,
        canShowTimeout: false,
    };

    componentDidMount() {
        window.addEventListener('unhandledrejection', this.catchPromiseRejection);
        window.addEventListener('error', this.catchError);
    }

    componentDidCatch(error: Error) {
        this.captureException(error);
    }

    componentWillUnmount() {
        window.removeEventListener('unhandledrejection', this.catchPromiseRejection);
        window.removeEventListener('error', this.catchError);
    }

    captureException = (error: Error) => {
        console.error(error);
        captureException(error);
        if (error != null && typeof error === 'object') {
            this.setState({ error });
        }
    };

    catchError = (event: ErrorEvent) => {
        const error = event.error != null ? event.error : event;
        this.captureException(error);
    };

    catchPromiseRejection = (event: PromiseRejectionEvent) => {
        const error = event.reason;
        this.captureException(error);
    };

    reloadPage = () => {
        window.location.reload();
    };

    goHome = () => {
        this.setState({ error: undefined });
        this.props.router.push('/');
    };

    render() {
        const { error } = this.state;

        let errorContent = null;

        if (error) {
            // [TODO] Handle network error, Unauthorized, Offline etc...
            // Basic error
            errorContent = (
                <div className={css.modalDialog}>
                    <div className={css.modalDialogContent}>
                        <h1 className={css.header}>Oops!</h1>
                        <p className={css.text}>Something went wrong.</p>
                        <div className={css.errorContent}>{error.message}</div>
                        <div className={css.buttons}>
                            <RKLButton size="medDesktop" color={'white'} onClick={this.goHome}>
                                Go Home
                            </RKLButton>
                            <RKLButton size="medDesktop" color={'violet'} onClick={this.reloadPage}>
                                Reload
                            </RKLButton>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <>
                {error && errorContent && <div className={css.overlay}>{errorContent}</div>}
                {this.props.children}
            </>
        );
    }
}
